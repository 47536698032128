/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { reportListAction, downloadReportListAction } from 'Admin/actions/report';
import MoreIc from "../../../Front/assets/img/icons/more.svg";
import ReportsDetail from './ReportsDetail';
import Select from 'react-select';
import { featureWiseReportOptions } from "../../variables/Variables"
import { CSVLink } from "react-csv";


let csvReportHeaders = [
    { label: "Sno", key: "sNo" },
    { label: "Clinic Id", key: "clinicId" },
    { label: "Dr Name", key: "doctorName" },
    { label: "Mobile No.", key: "mobileNo" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Reg Date", key: "regDate" },
    { label: "Exp Date", key: "expDate" },
    { label: "Dr Specialization", key: "specializations" },
    { label: "Degree", key: "degree" },
    { label: "Total Patients", key: "patientCount" },
    { label: "Total App Download", key: "appCount" },
    { label: "Non App Users", key: "nonAppPatientCount" },
    { label: "Knowledge Created", key: "knowledgeCreatedCount" },
    { label: "PA Uses (No / Occasional / Frequent)", key: "paUses" },
    { label: "Star Status", key: "starStatus" },
    { label: "Icons", key: "tags" },
    { label: "Next Visit Date Given To Patients", key: "nextVisitPatientCount" },
    { label: "Patients Using Chat", key: "totalPatientUseChat" },
    { label: "Diet Chart Shared", key: "dietShareCount" },
    { label: "Insulin Calculator Active Users", key: "insulinCount" },
    // { label: "Report Users", key: "dietShareCount" },
    { label: "Glucose Diary Users", key: "totalGlucoseDiaryUser" },
    { label: "EMR Users", key: "emrCount" },
    { label: "No. Of Appointment", key: "appointmentCount" },


];



class ReportList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportList: [],
            isLoading: true,
            asc_desc: true,
            userRow: null,
            totalCount: 0,
            currentPage: 1,
            sizePerPage: 10,
            searchText: "",
            formData: {
                direction: 'desc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                start_date: '',
                end_date: '',
                startDate: '',
                feature: ""
            },
            limitForm: {},
            moreDetails: false,
            featuresList: featureWiseReportOptions,
            downloadReportList: [],
            isDownloadingReprt: false,
            startDate: "",
            endDate: ""
        }

        this.onPageChange = this.onPageChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onUserCount = this.onUserCount.bind(this);
        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
        this.moreDetails = this.moreDetails.bind(this);
        this.onDismissMoreDetail = this.onDismissMoreDetail.bind(this);
        this.featureChangeHandler = this.featureChangeHandler.bind(this);
        this.getCity = this.getCity.bind(this);
        this.getState = this.getState.bind(this);
        this.getRegistrationDate = this.getRegistrationDate.bind(this);
        this.getExpiryDate = this.getExpiryDate.bind(this);
        this.getSpecialization = this.getSpecialization.bind(this);
        this.getDietChartData = this.getDietChartData.bind(this);
        this.getMobileNo = this.getMobileNo.bind(this);


        // this.dateFilter = this.dateFilter.bind(this);

    }



    componentDidMount() {
        this.props.reportListAction(this.state.formData);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isReportList !== this.props.isReportList) {


            let j = this.state.currentPage * 10 - 10;
            let reportList = [];
            if (nextProps.ReportList.data && nextProps.ReportList.data.data && nextProps.ReportList.data.data.length > 0) {
                reportList = nextProps.ReportList.data.data.map((key, i) => {
                    let row = {
                        id: key._id,
                        pId: key._id,
                        sn: ++j,
                        clinicId: key.clinicId,
                        name: key.name,
                        video: key.shareVideoTCount + ' / ' + key.videoShareCount + ' / ' + key.videoViewCount,
                        //  document: key.docTCount + ' / ' + key.docPrintCount + ' / ' + key.docShareCount + ' / ' + key.docviewCount,
                        document: key.docTCount + ' / ' + key.docShareCount + ' / ' + key.docviewCount,
                        task: key.taskTCount + ' / ' + key.taskCount,
                        app: key.app,
                        diet: key.dietShareTCount + ' / ' + key.dietPrintCount + ' / ' + key.dietShareCount + ' / ' + key.dietEmailCount,
                    };
                    return { ...key, ...row };
                });
            }

            // reportList?.map((v) => {
            //     v.state_outer = v?.address?.state ?? ""
            //     v.city_outer = v?.address?.city ?? ""
            // })

            this.setState({
                reportList: reportList,
                totalCount: nextProps.ReportList.data.count,
                fetchRequest: true,
                totalPatientCount: nextProps.ReportList.totalPatientCount,
                totalappCount: nextProps.ReportList.totalappCount
            })


            // this.setState({patientList:patientList});
            if (this.state.isLoading === true) {
                this.setState({ isLoading: false });
            }
        }

        if (nextProps.isDownloadReportList !== this.props.isDownloadReportList) {
            let list = nextProps.DownloadReportList.data.data
            let downloadList = []
            for (let i = 0; i < list.length; i++) {
                let tempObj = {}
                tempObj["sNo"] = i + 1
                tempObj["clinicId"] = list[i].clinicId
                tempObj["doctorName"] = list[i].doctorName
                tempObj["mobileNo"] = list[i].address.phone
                tempObj["city"] = list[i].address.city
                tempObj["state"] = list[i].address.state
                tempObj["regDate"] = moment(list[i].subscription_start_date).format("DD-MM-YYYY")
                tempObj["expDate"] = moment(list[i].subscription_end_date).format("DD-MM-YYYY")
                let strSpecializations = ""
                let arrSpecialization = list[i].specializations
                for (let j = 0; j < arrSpecialization.length; j++) {
                    strSpecializations += arrSpecialization[j].name
                    if (j < arrSpecialization.length - 1)
                        strSpecializations += " , "
                }
                tempObj["specializations"] = strSpecializations
                tempObj["degree"] = list[i].degree
                tempObj["patientCount"] = list[i].patientCount
                tempObj["appCount"] = list[i].appCount
                tempObj["nonAppPatientCount"] = list[i].nonAppPatientCount
                tempObj["knowledgeCreatedCount"] = list[i].knowledgeCreatedCount
                tempObj["paUses"] = list[i].paUsers ? list[i].paUsers.length > 0 ? list[i].paUsers[0].performanceStatus : "Never" : "Never"
                tempObj["tags"] = list[i].totalTags > 0 ? "Using" : "Not Using"
                tempObj["starStatus"] = list[i].totalStarStatus > 0 ? "Using" : "Not Using"
                tempObj["nextVisitPatientCount"] = list[i].nextVisitPatientCount
                tempObj["totalPatientUseChat"] = list[i].totalPatientUseChat
                tempObj["dietShareCount"] = list[i].dietShareCount
                tempObj["insulinCount"] = list[i].insulinCount
                tempObj["totalGlucoseDiaryUser"] = list[i].totalGlucoseDiaryUser
                tempObj["emrCount"] = list[i].emrCount
                tempObj["appointmentCount"] = list[i].appointmentCount
                downloadList.push(tempObj)
            }
            this.setState({ downloadReportList: downloadList, isDownloadingReprt: false })
        }




    }

    handleStartDate(date) {
        var selectedDate = moment(date).format('YYYY-MM-DD');
        let field = this.state.formData;
        field['start_date'] = selectedDate;
        this.setState({ formData: field, startDate: moment(date).format('DD-MM-YYYY') });
    }

    handleEndDate(date) {
        var endDate = moment(date).format('YYYY-MM-DD');
        let field = this.state.formData;
        field['end_date'] = endDate;
        this.setState({ formData: field, endDate: moment(date).format('DD-MM-YYYY') });
    }

    dateFilter(type) {
        let field = this.state.formData;
        if (type === 'clear') {
            field['start_date'] = "";
            field['end_date'] = "";
            field['search'] = "";
            this.setState({ formData: field, startDate: "", endDate: "" });
        }

        this.setState({ downloadReportList: [] })
        if (type === "search") {
            this.setState({ isDownloadingReprt: true })
            this.props.downloadReportListAction(field);
        }
    }

    valid(current) {
        let today = moment().format('YYYY-MM-DD');
        current = moment(current).format('YYYY-MM-DD');
        return today >= current;
    }

    valid2(current, state) {
        let today = moment().format('YYYY-MM-DD');
        current = moment(current).format('YYYY-MM-DD');
        let startDate = moment(state.formData.start_date).format('YYYY-MM-DD');
        return today >= current && startDate <= current;
    }

    onPageChange(page, sizePerPage) {
        this.setState({ isLoading: true });
        let data = {};
        if (this.state.formData.feature === "patient")
            data["getPatientCount"] = true;

        if (this.state.formData.feature === "app")
            data["getAppCount"] = true;

        if (this.state.formData.feature === "emr")
            data["getEMRCount"] = true;

        if (this.state.formData.feature === "dietChart")
            data["getDietCount"] = true;

        if (this.state.formData.feature === "glucoseDiary")
            data["getGlucoseCount"] = true;

        if (this.state.formData.feature === "report")
            data["getReportCount"] = true;

        if (this.state.formData.feature === "insulinCalculator")
            data["getInsulinCount"] = true;

        if (this.state.searchText === "") {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
            data['start_date'] = this.state.formData.start_date;
            data['end_date'] = this.state.formData.end_date;
        } else {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
            data['start_date'] = this.state.formData.start_date;
            data['end_date'] = this.state.formData.end_date;
            data['search'] = this.state.searchText;
        }

        this.setState({ sizePerPage: sizePerPage })
        this.setState({ currentPage: page })
        this.props.reportListAction(data)

        if (this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage) {
            eval('$(".ps").scrollTop(0)');
        }
    }

    onSearchChange(text) {
        this.setState({ isLoading: true });
        if (text !== "") {
            if (text.length >= 1) {
                let data = {}

                if (this.state.formData.feature === "patient")
                    data["getPatientCount"] = true;

                if (this.state.formData.feature === "app")
                    data["getAppCount"] = true;

                if (this.state.formData.feature === "emr")
                    data["getEMRCount"] = true;

                if (this.state.formData.feature === "dietChart")
                    data["getDietCount"] = true;

                if (this.state.formData.feature === "glucoseDiary")
                    data["getGlucoseCount"] = true;

                if (this.state.formData.feature === "report")
                    data["getReportCount"] = true;

                if (this.state.formData.feature === "insulinCalculator")
                    data["getInsulinCount"] = true;

                data['direction'] = this.state.formData.direction;
                data['order'] = this.state.formData.order;
                data['offset'] = this.state.formData.offset;
                data['limit'] = this.state.formData.limit;
                data['search'] = text;
                data['start_date'] = this.state.formData.start_date;
                data['end_date'] = this.state.formData.end_date;

                // let field = this.state.formData;
                // field['search'] = text;
                // this.setState({ formData: field });

                this.setState({ sizePerPage: 10 })
                this.setState({ currentPage: 1 })
                this.props.reportListAction(data);

            }
        } else {
            setTimeout(() => {
                this.componentDidMount();
            }, 100);
        }
        this.setState({ searchText: text });
    }

    sortHandle() {
        this.setState({ asc_desc: !this.state.asc_desc });
        let data = this.state.formData;
        data.direction = this.state.asc_desc ? "asc" : "desc"
        this.setState({ formData: data });
        this.props.reportListAction(data);
    }

    onUserCount(cell, row, enumObject, rowIndex) {
        return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
    }


    _setTableOption() {
        if (this.state.isLoading) {
            return (
                <Loading
                    type="bars"
                    color="#000000"
                    style={{ margin: "0px auto", width: "40px" }}
                />
            );
        } else {
            return "No data found!";
        }
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    moreDetails(cell, row) {
        return (
            <Link
                onClick={() => {

                    this.setState({
                        moreDetails: true,
                        moreDetailsData: row,
                    });
                }}
            >
                <img
                    title="More Details"
                    className="table-action-ic"
                    src={MoreIc}
                    alt="more"
                />
            </Link>
        );
    }

    onDismissMoreDetail() {
        this.setState({
            moreDetails: false,
        });
    }

    featureChangeHandler(e) {
        this.setState({ isLoading: true, reportList: [] });
        let tempData = this.state.formData
        tempData.feature = e.target.value

        let data = {};
        if (e.target.value === "patient")
            data["getPatientCount"] = true;

        if (e.target.value === "app")
            data["getAppCount"] = true;

        if (e.target.value === "emr")
            data["getEMRCount"] = true;

        if (e.target.value === "dietChart")
            data["getDietCount"] = true;

        if (e.target.value === "glucoseDiary")
            data["getGlucoseCount"] = true;

        if (e.target.value === "report")
            data["getReportCount"] = true;

        if (e.target.value === "insulinCalculator")
            data["getInsulinCount"] = true;

        //   if(e.target.value==="specialization")
        //   data["getPatientCount"]=true;

        if (this.state.searchText === "") {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = this.state.formData.offset;
            data['limit'] = this.state.formData.limit;
            data['start_date'] = this.state.formData.start_date;
            data['end_date'] = this.state.formData.end_date;
        } else {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = this.state.formData.offset;
            data['limit'] = this.state.formData.limit;
            data['start_date'] = this.state.formData.start_date;
            data['end_date'] = this.state.formData.end_date;
            data['search'] = this.state.searchText;
        }

        this.props.reportListAction(data)
        this.setState({ formData: tempData })
    }

    getCity(cell, row, enumObject, rowIndex) {
        let city = ""
        if (row?.address != undefined) {
            city = row?.address?.city
        }
        return city
    }

    getState(cell, row, enumObject, rowIndex) {
        let state = ""
        if (row?.address != undefined) {
            state = row?.address?.state
        }
        return state
    }

    getRegistrationDate(cell, row, enumObject, rowIndex) {
        let regDate = ""
        if (row?.subscription_start_date != undefined) {
            regDate = moment(row?.subscription_start_date).format("DD-MM-YYYY")
        }

        return regDate
    }

    getExpiryDate(cell, row, enumObject, rowIndex) {
        let expDate = ""
        if (row?.subscription_end_date != undefined) {
            expDate = moment(row?.subscription_end_date).format("DD-MM-YYYY")
        }

        return expDate
    }

    getSpecialization(cell, row, enumObject, rowIndex) {
        let specializations = ""
        // let arr = row?.specializations

        // for (let i = 0; i < arr.length; i++) {
        //     specializations += arr[i].name
        //     specializations += " "
        // }

        return specializations;
    }

    getDietChartData(cell, row, enumObject, rowIndex) {
        let strDiet = ""
        if (row.dietShareTCount != undefined && row.dietPrintCount != undefined && row.dietShareCount != undefined && row.dietEmailCount != undefined)
            strDiet = `${row.dietShareTCount} / ${row.dietPrintCount} / ${row.dietShareCount} / ${row.dietEmailCount}`
        return strDiet
    }

    getMobileNo(cell, row, enumObject, rowIndex) {
        return row?.address?.mobile ? row?.address?.mobile : ""
    }


    render() {

        //const selectRowProp = { mode: 'checkbox' };
        const options = {
            onSortChange: this.onSortChange,
            clearSearch: true,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
            sizePerPage: this.state.sizePerPage,
            onPageChange: this.onPageChange,
            page: this.state.currentPage,
            // onSearchChange: this.onSearchChange,
            onSizePerPageList: this.sizePerPageListChange,
            noDataText: this._setTableOption(),
        };

        const getDrugCreationDate = (cell, row, enumObject, rowIndex) => {
            return <span>{moment(row.createdAt).format("DD-MMM-YYYY")}</span>;
        };


        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        <Row>
                                            <Col>

                                                <FormGroup>
                                                    <Col className="spc-mangess" componentClass={ControlLabel} sm={1}>
                                                        From
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Datetime
                                                            timeFormat={false}
                                                            dateFormat={"DD-MM-YYYY"}
                                                            inputProps={{ placeholder: "DD-MM-YYYY" }}
                                                            maxDate={new Date()}
                                                            name={"from_date"}
                                                            value={this.state.startDate}
                                                            onChange={this.handleStartDate}
                                                            isValidDate={this.valid}
                                                            key={'start_Date' + this.state.startDate}
                                                        />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Col className="spc-mangess" componentClass={ControlLabel} sm={1}>
                                                        To
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Datetime
                                                            timeFormat={false}
                                                            dateFormat={"DD-MM-YYYY"}
                                                            inputProps={{ placeholder: "DD-MM-YYYY" }}
                                                            maxDate={new Date()}
                                                            name={"to_date"}
                                                            value={this.state.endDate}
                                                            onChange={this.handleEndDate}
                                                            isValidDate={e => this.valid2(e, this.state)}
                                                            key={'end_Date' + this.state.endDate}
                                                        />
                                                    </Col>
                                                    {this.state.downloadReportList.length > 0 &&
                                                        <Col>
                                                            <CSVLink
                                                                headers={csvReportHeaders}
                                                                data={this.state.downloadReportList}
                                                                filename={"ClinicReport.csv"}
                                                                className=""
                                                            >
                                                                Download clinic report (CSV)
                                                            </CSVLink>
                                                        </Col>
                                                    }
                                                </FormGroup>
                                                <FormGroup className='search-clear-btn'>
                                                    <Col sm={2}>
                                                        <button type="button" disabled={this.state.isDownloadingReprt} onClick={this.dateFilter.bind(this, 'search')} className="btn-fill btn-wd btn btn-primary spc-treport">Search
                                                            {this.state.isDownloadingReprt && <i class="fa fa-spinner fa-spin"></i>}
                                                        </button>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <button disabled={this.state.isDownloadingReprt} type="button" onClick={this.dateFilter.bind(this, 'clear')} className="btn-fill btn-wd btn spc-treport">Clear</button>
                                                    </Col>
                                                </FormGroup>



                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Col style={{ top: "15px" }} componentClass={ControlLabel} sm={1} >
                                                        Select Feature:
                                                    </Col>
                                                    <Col sm={3}>
                                                        <FormControl
                                                            componentClass="select"
                                                            name="state"
                                                            onChange={(e) => {
                                                                this.featureChangeHandler(e);
                                                            }}
                                                        >
                                                            <option value="">Please select</option>
                                                            {this.state.featuresList &&
                                                                this.state.featuresList.map(function (item, idx) {
                                                                    return (
                                                                        <option key={`feature_option_${idx}`} value={item.value}>
                                                                            {item.label}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </FormControl>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: "", marginBottom: '10px' }}>
                                            <Col md={4}>
                                                <div className="header notations-title">
                                                    Total Clinic:
                                                    <b> {((this.state.searchText === "" && this.state.totalCount > 0) ? this.state.totalCount - 3 : (this.state.searchText && this.state.totalCount > 0) ? this.state.totalCount : 0)} </b>
                                                    {/* <b> {((this.state.totalCount > 0 && (this.state.searchText !="" || !this.state.searchText)) ? this.state.totalCount - 3 :0)} </b> */}
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="header notations-title">
                                                    Total Patient :
                                                    <b> {this.state.totalPatientCount} </b>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="header notations-title">
                                                    Total App Download :
                                                    <b> {this.state.totalappCount} </b>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className='search-box-admin'>
                                            <div className='search clinic-uploads-search'>
                                                <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                                                <span className='input-group-btn'>
                                                    <Button onClick={(e) => { this.onSearchChange(e.target.value, "null") }}>Clear</Button>
                                                </span>
                                            </div>
                                        </div>
                                        <BootstrapTable
                                            className="report-table-main"
                                            deleteRow={false}
                                            multiColumnSearch={true}
                                            data={this.state.reportList}
                                            // search={true}
                                            selectRow="{selectRowProp}"
                                            pagination={true}
                                            options={options}
                                            striped hover condensed scrollTop={'Bottom'}
                                            remote={true}
                                            fetchInfo={{ dataTotalSize: this.state.totalCount }}
                                            sort={true}
                                        >
                                            <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': 'clinicId' }} dataField='clinicId' dataSort={true} isKey searchable={false}>Id</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '4%', textAlign: 'center' }} tdStyle={{ width: '4%', textAlign: 'center' }} tdAttr={{ 'data-attr': '#' }} dataFormat={this.onUserCount}>#</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '6%', textAlign: 'center' }} tdStyle={{ width: '6%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'clinicId' }} dataField='clinicId' >Clinic ID </TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '16%', textAlign: 'center' }} tdStyle={{ width: '16%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'doctorName' }} dataField='doctorName' >DR.NAME </TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '16%', textAlign: 'center' }} tdStyle={{ width: '16%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'NAME' }} dataField='name' >CLINIC NAME </TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Mobile' }} dataFormat={this.getMobileNo} dataField='mobile'>Mobile No.</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'City' }} dataField='mobile' dataFormat={this.getCity}>City</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'State' }} dataField='mobile' dataFormat={this.getState}>State</TableHeaderColumn>
                                            {/* <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'RegDate' }} dataField='mobile' dataFormat={this.getRegistrationDate}>Reg Date</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'ExpDate' }} dataField='mobile' dataFormat={this.getExpiryDate}>Exp Date</TableHeaderColumn> */}
                                            {/* <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Specialization' }} dataField='mobile' dataFormat={this.getSpecialization}>Specialization</TableHeaderColumn> */}
                                            {/* <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': ' DOCUMENTS (T/S/V)' }} dataField='document'> DOCUMENTS (T/S/V)</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'VIDEO (T/S/V)' }} dataField='video'>VIDEO (T/S/V)</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '5%', textAlign: 'center' }} tdStyle={{ width: '5%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'TASK (T/S)' }} dataField='task'>TASK (T/S)</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'DIET CHART (T/P/S/E)' }} dataField='diet'>DIET CHART (T/P/S/E)</TableHeaderColumn> */}
                                            {this.state.formData.feature === "patient" &&
                                                <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'PATIENT' }} dataField='patientCount'>PATIENT</TableHeaderColumn>
                                            }

                                            {this.state.formData.feature === "app" &&
                                                <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'APP' }} dataField='patientAppCount'>App Users</TableHeaderColumn>
                                            }

                                            {this.state.formData.feature === "emr" &&
                                                <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'APP' }} dataField='emrCount'>Total Prescription</TableHeaderColumn>
                                            }

                                            {this.state.formData.feature === "dietChart" &&
                                                <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'APP' }} dataField='emrCount' dataFormat={this.getDietChartData}>Diet Chart(T/S/P/E)</TableHeaderColumn>
                                            }

                                            {this.state.formData.feature === "glucoseDiary" &&
                                                <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'APP' }} dataField='glucoseCount'>Glucose Diary Users</TableHeaderColumn>
                                            }

                                            {this.state.formData.feature === "report" &&
                                                <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'APP' }} dataField='reportCount'>Report Shared</TableHeaderColumn>
                                            }

                                            {this.state.formData.feature === "insulinCalculator" &&
                                                <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'APP' }} dataField='insulinCount'>Insulin Calculator</TableHeaderColumn>
                                            }




                                            {/* <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'APP' }} dataField='appCount'>APP</TableHeaderColumn> */}
                                            <TableHeaderColumn
                                                thStyle={{ width: "6%", textAlign: 'center' }}
                                                tdStyle={{ width: "6%", textAlign: 'center' }}
                                                dataFormat={this.moreDetails}
                                            >
                                                Action
                                            </TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>

                                }
                            />
                        </Col>
                    </Row>
                </Grid>
                <Modal
                    backdrop="static"
                    className="pa-next-visit modal-more-info"
                    show={this.state.moreDetails}
                    onHide={() => this.setState({ moreDetails: false })}
                    dialogClassName="modal-lg"
                >
                    <Modal.Header closeButton className="bg-thumbcolor">
                        <Modal.Title id="example-modal-sizes-title-lg">
                            {`${this.state.moreDetailsData?.clinicId}
                                            / ${this.state.moreDetailsData?.name} /
                                        ${this.state.moreDetailsData?.doctorName} \u00A0\u00A0`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="Knowledge-Share ">
                        <ReportsDetail
                            moreDetails={this.state.moreDetailsData}
                            onDismissMoreDetail={this.onDismissMoreDetail}
                            formData={this.state.formData}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {

        ReportList: state.report.ReportList,
        isReportList: state.report.isReportList,
        isReportListError: state.report.isReportListError,
        isDownloadReportList: state.report.isDownloadReportList,
        isDownloadReportListError: state.report.isDownloadReportListError,
        DownloadReportList: state.report.DownloadReportList,
    }
}
export default withRouter(connect(mapStateToProps, { reportListAction, downloadReportListAction })(ReportList));
