/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import React, { useState, useEffect, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "Front/api/appointment";

import { appTimeSlot } from "Admin/variables/Variables.jsx";

import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import Select from "react-select";
import Calendar from "../../../components/Calendar";
import axios from "axios";
import { appConstants } from "../../../_constants/app.constants";
import { style } from "Admin/variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import * as PF from "Front/views/Home/PublicFunction.jsx";
//import AsyncSelect from 'react-select/async';

const AddAppointment = ({
  getListAction,
  data,
  setModal,
  ModalStatus,
  addAppointment,
  setAddAppointment,
  fromPatientDetail,
}) => {
  let history = useHistory();
  const [formData, setFormData] = useState({ ...data });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [alertData, setAlert] = useState(null);
  const [tempData, setTempData] = useState({});
  const [alreadyExistStatus, setAlreadyExistStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [appointmentList, setAppointmentList] = useState([]);
  const [folloupStatus, setFolloupStatus] = useState(false);
  const [selectPatient, setSelectPatient] = useState();
  const [isNumber, setIsNumber] = useState(false);
  const notificationSystem = useRef(null);
  const inputHandler = (e, field = "") => {
    let saveFormData = formData;
    if (field === "date") {
      let date = e;
      saveFormData[field] = date;
    } else {
      if (e.target.name === "phone") {
        if (/^[0-9]+$/.test(e.target.value)) {
          setIsNumber(false);
        } else {
          if (e.target.value.length === 0 && e.target.value === "")
            setIsNumber(false);
          else {
            setIsNumber(true);
            return;
          }
        }
      }

      if (
        e.target.name === "name" ||
        e.target.name === "city" ||
        e.target.name === "refereedBy"
      ) {
        saveFormData[e.target.name] = e.target.value.toUpperCase();
      } else {
        saveFormData[e.target.name] =
          e.target.type === "checkbox"
            ? e.target.checked
              ? e.target.value
              : ""
            : e.target.value;
      }

      if (e.target.name === "folloupStatus" && e.target.value === "on") {
        selectedValue["patientId"] = "";
        setSelectedValue({ ...selectedValue });
        setFolloupStatus(true);
      } else {
        setFolloupStatus(false);
      }
    }
    setFormData({ ...formData, ...saveFormData });
    validate();
  };

  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = true;
    if (post.name && post.name != "") {
      errArr.name = "";
      //errflag=true;
    } else {
      errArr.name = "error";
      errflag = false;
    }

    if (
      post.date &&
      post.date != "" &&
      moment(post.date, "YYYY-MM-DD").isValid() &&
      moment(formData.date, "YYYY-MM-DD").format("YYYY-MM-DD") >=
      moment().format("YYYY-MM-DD")
    ) {
      errArr.date = "";
      //errflag=true;
    } else {
      errArr.date = "error";
      errflag = false;
    }

    if (post.time && post.time != "") {
      errArr.time = "";
      //errflag=true;
    } else {
      errArr.time = "error";
      errflag = false;
    }
    
    if (
      post.phone &&
      post.phone.length > 0 &&
      (post.phone.length > 10 || post.phone.length < 10)
    ) {
      errArr.phone = "error";
      errflag = false;
      //errflag=true;
    } 
    // else if(localStorage.getItem("is_mobile_no_mandatory")==="yes") {
    //         errArr.phone = "error";
    // }
    else  errArr.phone = "";

    if (post.age > 150) {
      errArr.age = "error";
      errflag = false;
      //errflag=true;
    } else {
      errArr.age = "";
    }

    setErrors({ ...errArr });
    return errflag;
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (validate() && moment(formData.date, "YYYY-MM-DD").isValid()) {
      setIsLoading(true);
      let data = formData;
      let resp = {};
      data["date_time"] = new Date(
        moment(formData.date).format("YYYY-MM-DD") + " " + formData.time
      );
      data["type"] = formData.type === "online" ? "online" : "offline";
      if(data["age"] !== undefined){
        let dob = moment().subtract("years", data["age"]);
        data["dob"] = moment(dob._d).format("YYYY-MM-DD");
      }
      if (addAppointment === false) {
        data["appointmentId"] = formData._id;
        resp = await API.updateAppointment(data);
        if (resp.data.isRedirect) {
          history.push({ pathname: "/dashboard" });
        }
      } else {
        delete data["_id"];
        if (formData.folloupStatus === "") {
          delete data["patientId"];
          delete data["patientClinicId"];
        }
        // console.log(data,"addAppointment")
        resp = await API.addAppointment(data);
      }

      if (resp.data && resp.data.type === "already_exist") {
        setAlreadyExistStatus(true);
        setTempData({ ...resp.data.old_appointment });
      } else {
        setAlreadyExistStatus(false);
        if (addAppointment === false) {
          successAlert("You have successfully Update appointment");
        } else {
          successAlert("You have successfully added an appointment");
        }
        if (getListAction !== undefined) {
          getListAction();
        }
        setIsLoading(false);
        setSelectPatient();
      }
    }
  };
  const successAlert = (msg) => {
    setModal(false);
    const notification = notificationSystem.current;
    notification.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: <div>{msg}</div>,
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  };

  useEffect(() => {
    if (ModalStatus === true && addAppointment === false) {
      let age=""
      if(data.age!=undefined && data.age!=null){
        age=data.age;
        if(data.dob!=undefined){
          age=PF.getAgeByDob(data.dob)
        }
      }
      setFormData({ ...data,age:age });
    }
  }, [ModalStatus]);

  const addAppointmentFun = () => {
   setAddAppointment(true);
    setModal(true);
    getListAction === undefined
      ? setFormData(data)
      : setFormData({ type: "offline" });
  };

  const editAppointPopup = () => {
    setAddAppointment(false);
    setFormData({ ...tempData });
    setAlreadyExistStatus(false);
  };

  const selectHandler = (e, name) => {
    let data = JSON.parse(e);
    let userDetail =
      data.firstName + " " + data.lastName + "(" + data.patientClinicId + ")";
    setSelectPatient(userDetail);
    if (data) {
      let temp = selectedValue;
      temp[name] = data._id;
      setSelectedValue(temp);
      // console.log(appointmentList)
      // let filtered = appointmentList[data._id];
      let filtered = data;
      let formDataTemp = formData;
      formDataTemp["name"] = filtered.firstName + " " + filtered.lastName;
      filtered["age"]=PF.getAgeByDob(data.dob);
      filtered["dob"]=data.dob
      delete filtered["_id"];
      setFormData({ ...formDataTemp, ...filtered });
    } else {
      setSelectedValue({});
      setFormData({
        type: formData.type,
        folloupStatus: formData.folloupStatus,
      });
    }
    // if(e){
    //     let temp  = selectedValue;
    //     temp[name] = e.value;
    //     setSelectedValue(temp);
    //     let filtered = appointmentList[e.value];
    //     let formDataTemp = formData;
    //     formDataTemp['name'] = filtered.firstName+" "+filtered.lastName;
    //     delete filtered['_id'];
    //     setFormData({...formDataTemp,...filtered})
    // }else{
    //     setSelectedValue({});
    //     setFormData({type:formData.type,folloupStatus: formData.folloupStatus})
    // }
  };

  const handleSelectAccess = async (e) => {
    if (formData.type === undefined) {
      formData["patientId"] = "";
      selectedValue["patientId"] = "";
      formData["type"] = "offline";
      formData["folloupStatus"] = "";
    } else {
      formData["folloupStatus"] = "on";
    }
    setFormData({ ...formData });
    let res = await API.patientList({ patient_id: e });
    const unique = appointmentList;
    if (res.data && res.data.status === "Success") {
      if (res.data.data.data) {
        res.data.data.data.map((item) => {
          return (unique[item._id] = {
            value: item._id,
            label: item.patientClinicId,
            ...item,
          });
        });
        setAppointmentList({ ...unique, ...appointmentList });
        const options = Object.keys(unique).map((item) => {
          return {
            value: unique[item]["_id"],
            label: unique[item]["patientClinicId"],
            ...unique[item],
          };
        });
        setSelectedOption(unique);
        return { options: [...options] };
      }
    }
  };
  const CancelToken = axios.CancelToken;
  const patientSearch = async (e) => {
    setSelectPatient(e.target.value);
    let userDetail = e.firstName + "(" + e.patientClinicId + ")";
    let cancel;
    let search = e.target.value.trim();
    let sessionToken = localStorage.getItem("token");
    let flag = "brand";
    let radioSearchType = document.getElementsByName("search-type");
    for (var radio of radioSearchType) {
      if (radio.checked) {
        flag = radio.value;
      }
    }
    if (search !== "") {
      if (!/[^a-zA-Z0-9  .]/.test(search)) {
        if (cancel !== undefined) {
          cancel();
        }
        axios
          .get(appConstants.paAppURL + "patient-search?search=" + search, {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
            headers: {
              Authorization: sessionToken,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            // console.log(response)
            autocomplete(
              document.getElementById("txtsearchmedicine"),
              response.data.data
            );
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
            }
          });
      }
    } else {
      closeLists();
    }
  };
  function closeLists(elmnt) {
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
  }
  function autocomplete(inp, arr) {
    function closeAllLists(elmnt) {
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }

    var currentFocus;

    var a,
      b,
      i,
      val = inp.value;
    closeAllLists();
    if (!val) {
      return false;
    }
    currentFocus = -1;
    a = document.createElement("DIV");
    a.setAttribute("id", inp.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items autocompletedropdownmenu");
    inp.parentNode.appendChild(a);
    for (i = 0; i < arr.length; i++) {
      b = document.createElement("DIV");
      b.setAttribute("class", "");
      b.innerHTML +=
        arr[i].firstName +
        " " +
        arr[i].lastName +
        " ( " +
        arr[i].patientClinicId +
        (" , Age: " + PF.getAgeByDob(arr[i].dob) + " Y") +
        (arr[i].phone ? " , Contact: " + arr[i].phone : "") +
        (arr[i].city ? ", " + arr[i].city : "") +
        (arr[i].height ? ", " + arr[i].height + " CM" : "") +
        (arr[i].weight ? " , " + arr[i].weight + " KG" : "") +
        " )";
      b.innerHTML +=
        "<input type='hidden' patientData='" +
        JSON.stringify(arr[i]) +
        "' patientId='" +
        arr[i]._id +
        "' value='" +
        arr[i].firstName +
        " " +
        arr[i].lastName +
        "'>";
      b.addEventListener("click", function (e) {
        let patientId =
          this.getElementsByTagName("input")[0].getAttribute("patientData");
        // selectHandler(this.getElementsByTagName("input")[0].getAttribute("drugId"))
        selectHandler(patientId, "patientId");
        closeAllLists();
      });
      a.appendChild(b);
    }
  }
  useEffect(() => {
    if (!fromPatientDetail) {
      handleSelectAccess();
    }
  }, []);

  useEffect(() => {
    setSelectPatient();
    if (formData.patientId) {
      formData["folloupStatus"] = "on";
      setSelectedValue({ patientId: formData.patientId });
    }

    if (formData.patientId === "" && addAppointment === false) {
      formData["folloupStatus"] = "";
      setSelectedValue({ patientId: "" });
    }
  }, [formData]);

  
  return (
    <span>
      {alertData}
      <NotificationSystem ref={notificationSystem} style={style} />
      {getListAction !== undefined ? (
        <button
          type="button"
          className="btn-fill btn btn-primary btn btn-default mangeBtns"
          onClick={(e) => addAppointmentFun()}
        >
          Add New Appointment
        </button>
      ) : (
        <div className="flex-col right-info">
          {/* <button className='btn btn-primary btn-fill' style={{ marginRight: '5px' }}>Edit Patient Info</button> */}
          {/* <button type="button" className="btn btn-primary btn-fill" onClick={e => { this.setState({ patientModal: true }) }} style={{ marginRight: '5px' }}>Edit Patient Info</button> */}
          <button
            className="btn btn-primary btn-fill"
            onClick={(e) => addAppointmentFun()}
          >
            Book Next Appointment
          </button>
        </div>
      )}

      {/* <Modal className="pa-patient-registration" show={this.state.patientModal} onHide={() => this.setState({ patientModal: false })} dialogClassName="modal-lg">
                <Modal.Header closeButton className="bg-thumbcolor">
                    <Modal.Title id="example-modal-sizes-title-lg">Edit Patient Info</Modal.Title>
                </Modal.Header>
                <Modal.Body className="Knowledge-Share card">
                    <Row>
                        <Col md={12}>
                            <PatientRegistration onDismiss={this.onDismiss} patientData={this.props.pvDetail} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal> */}

      <Modal
        show={ModalStatus}
        onHide={() => setModal(false)}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">
            {getListAction !== undefined
              ? addAppointment === true
                ? "Add New Appointment"
                : "Edit Appointment"
              : "Book Next Appointment"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="Knowledge-Share">
          <Row>
            <Col md={12}>
              <Form
                horizontal
                noValidate
                validated={validated}
                onSubmit={(e) => submitHandler(e)}
              >
                {formData.folloupStatus !== "" &&
                  formData.folloupStatus !== undefined &&
                  addAppointment &&
                  getListAction !== undefined && (
                    <Col sm={12} md={12}>
                      <FormGroup>
                        <Col componentClass={ControlLabel} sm={12} md={4}>
                          Patient Search <span className="star"></span>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="add-appointment-select-style">
                            <input
                              type="text"
                              class="form-control "
                              id="txtsearchmedicine"
                              value={selectPatient}
                              onChange={(e) => {
                                patientSearch(e);
                              }}
                              placeholder="Search Patient"
                              autoComplete="off"
                            />
                            {/* <Select.Async                                                    
                                                    value={selectedValue.patientId}
                                                    onChange={e=>selectHandler(e,'patientId')}
                                                    loadOptions={(e) => handleSelectAccess(e)}
                                                    valueKey='value'
                                                    labelKey='label'
                                                    autoload={true}
                                                    name='patientId'
                                                    cache={false}
                                                /> */}
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          {/* <FormControl type="text" name="patientClinicId" value={formData.patientClinicId?formData.patientClinicId:''} onChange={e => { inputHandler(e); }} placeholder="patientId" className={'insulin-input '+(errors.patientClinicId?errors.patientClinicId:'')} /> */}
                        </Col>
                        {alreadyExistStatus && (
                          <span>
                            Appointment already exist.{" "}
                            <span
                              onClick={(e) => {
                                editAppointPopup(e);
                              }}
                            >
                              {" "}
                              <a style={{ color: "blue" }}>
                                Click here to check.
                              </a>
                            </span>
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  )}
                {getListAction === undefined && alreadyExistStatus && (
                  <span
                    style={{
                      display: "block",
                      textAlign: "center",
                      paddingLeft: "25px",
                      marginBottom: "10px",
                    }}
                  >
                    Appointment already exist.{" "}
                    <span
                      onClick={(e) => {
                        editAppointPopup(e);
                      }}
                    >
                      {" "}
                      <a style={{ color: "blue" }}>Click here to check.</a>
                    </span>
                  </span>
                )}
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Patient Name <span className="star">*</span>
                    </Col>
                    <Col sm={12} md={6}>
                      <Row>
                        <Col sm={12} md={8}>
                          {selectedValue.patientId && (
                            <FormControl
                              type="text"
                              autocomplete="off"
                              name="name"
                              value={formData.name ? formData.name : ""}
                              disabled
                              placeholder="Name"
                              className={
                                "insulin-input " +
                                (errors.name ? errors.name : "")
                              }
                            />
                          )}
                          {!selectedValue.patientId && (
                            <FormControl
                              type="text"
                              autocomplete="off"
                              name="name"
                              value={formData.name ? formData.name : ""}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              placeholder="Name"
                              className={
                                "insulin-input " +
                                (errors.name ? errors.name : "")
                              }
                            />
                          )}
                        </Col>
                        {addAppointment && getListAction !== undefined && (
                          <Col sm={12} md={4} className="p5">
                            Follow Up{" "}
                            <input
                              type="checkbox"
                              name="folloupStatus"
                              checked={
                                formData.folloupStatus === "on" ? "true" : ""
                              }
                              onClick={(e) => {
                                inputHandler(e);
                              }}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Date<span className="star">*</span>
                    </Col>
                    <Col sm={12} md={6}>
                      {/* <FormControl type="Date" name="date" min={moment().format('YYYY-MM-DD')} value={formData.date ? moment(formData.date).format('YYYY-MM-DD') : ''} onChange={e => { inputHandler(e); }} placeholder="Date" className={'insulin-input ' + (errors.date ? errors.date : '')} /> */}

                      <Calendar
                        selectedDate={
                          formData.date
                            ? moment(formData.date).format("YYYY-MM-DD")
                            : ""
                        }
                        handleChange={(e) =>
                          inputHandler(moment(e).format("YYYY-MM-DD"), "date")
                        }
                        data={{
                          min: moment().format("YYYY-MM-DD"),
                          class:
                            "insulin-input " + (errors.date ? errors.date : ""),
                          valid: true,
                          backDateDisabled: true,
                        }}
                        errorColor={folloupStatus}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Time <span className="star">*</span>
                    </Col>
                    <Col sm={12} md={6}>
                      <FormControl
                        componentClass="select"
                        onChange={(e) => {
                          inputHandler(e);
                        }}
                        name="time"
                        value={formData.time ? formData.time : ""}
                        className={
                          "insulin-input " + (errors.time ? errors.time : "")
                        }
                      >
                        <option value="">Select Time</option>{" "}
                        {appTimeSlot.map(function (item) {
                          if (
                            formData.date &&
                            moment(formData.date).format("YYYY-MM-DD") ===
                            moment().format("YYYY-MM-DD") &&
                            moment(formData.date + " " + item.value).format(
                              "YYYY-MM-DD HH:mm:ss"
                            ) <= moment().format("YYYY-MM-DD HH:mm:ss")
                          ) {
                            return "";
                          } else {
                            return (
                              <option value={item.value}>{item.label}</option>
                            );
                          }
                        })}
                      </FormControl>
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Gender <span className="star"></span>
                    </Col>
                    <Col sm={12} md={6}>
                      {selectedValue.patientId && (
                        <FormControl
                          type="text"
                          name="gender"
                          value={formData.gender ? formData.gender : ""}
                          disabled
                          placeholder="Gender"
                          className={
                            "insulin-input " +
                            (errors.gender ? errors.gender : "")
                          }
                        />
                      )}
                      {!selectedValue.patientId && (
                        <FormControl
                          componentClass="select"
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                          name="gender"
                          value={formData.gender ? formData.gender : ""}
                          className={
                            "insulin-input " +
                            (errors.gender ? errors.gender : "")
                          }
                        >
                          <option value="">Select Gender</option>
                          <option value="Female">Female</option>
                          <option value="Male">Male</option>
                        </FormControl>
                      )}
                    </Col>
                  </FormGroup>
                </Col>

                {/* <Col sm={12} md={12}>
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={12} md={4}>
                                            Height(CM) <span className="star">*</span><br/>
                                            <small>Optional</small>
                                        </Col>
                                        <Col sm={12} md={6}>   
                                            <FormControl type="text" autocomplete="off" name="height" maxLength="6" value={formData.height ? formData.height : ''} onChange={e => { inputHandler(e); }} placeholder="Height" className={'insulin-input ' + (errors.height ? errors.height : '')} />                                           
                                        </Col>
                                    </FormGroup>
                                </Col> */}

                {/* <Col sm={12} md={12}>
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={12} md={4}>
                                            Weight(KG) <span className="star">*</span><br/>
                                            <small>Optional</small>
                                        </Col>
                                        <Col sm={12} md={6}>   
                                            <FormControl type="text" autocomplete="off" name="weight" maxLength="6" value={formData.weight ? formData.weight : ''} onChange={e => { inputHandler(e); }} placeholder="Weight" className={'insulin-input ' + (errors.weight ? errors.weight : '')} />                                           
                                        </Col>
                                    </FormGroup>
                                </Col> */}

                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      City <span className="star"></span>
                    </Col>
                    <Col sm={12} md={6}>
                      <FormControl
                        type="text"
                        autocomplete="off"
                        name="city"
                        value={formData.city ? formData.city : ""}
                        onChange={(e) => {
                          inputHandler(e);
                        }}
                        placeholder="City"
                        className={
                          "insulin-input " + (errors.city ? errors.city : "")
                        }
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Age <span className="star"></span>
                    </Col>
                    <Col sm={12} md={6}>
                      {selectedValue.patientId && (
                        <FormControl
                          type="number"
                          autocomplete="off"
                          name="age"
                          value={formData.age>=0 ? formData.age : ""}
                          disabled
                          placeholder="Age"
                          className={
                            "insulin-input " + (errors.age ? errors.age : "")
                          }
                        />
                      )}
                      {!selectedValue.patientId && (
                        <FormControl
                          type="number"
                          autocomplete="off"
                          name="age"
                          value={formData.age ? formData.age : ""}
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                          placeholder="Age"
                          className={
                            "insulin-input " +
                            (errors.age || errors.age <= 140 ? errors.age : "")
                          }
                        />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Referred by Doctor
                    </Col>
                    <Col sm={12} md={6}>
                      <FormControl
                        type="text"
                        autocomplete="off"
                        name="refereedBy"
                        value={formData.refereedBy ? formData.refereedBy : ""}
                        onChange={(e) => {
                          inputHandler(e);
                        }}
                        placeholder="Refereed by doctor"
                        className={
                          "insulin-input " +
                          (errors.refereedBy ? errors.refereedBy : "")
                        }
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Contact Number 
                      {/* {localStorage.getItem("is_mobile_no_mandatory")==="yes" ? <span className="star">*</span> :""} */}
                    </Col>
                    <Col sm={12} md={6}>
                      <FormControl
                        type="text"
                        autocomplete="off"
                        min="0"
                        maxLength="10"
                        name="phone"
                        value={formData.phone ? formData.phone : ""}
                        onChange={(e) => {
                          inputHandler(e);
                        }}
                        placeholder="Phone"
                        className={
                          "insulin-input " + (errors.phone ? errors.phone : "")
                        }
                      />
                      {isNumber && (
                        <p style={{ color: "red" }}>Only Numbers Are Allowed</p>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Appointment Type<span className="star"></span>
                    </Col>
                    <Col sm={12} md={6} className="e-consultation">
                      <div
                        componentClass={ControlLabel}
                        className="um-mandentory"
                      >
                        <form className="consultation-radio">
                          <input
                            name="type"
                            type="radio"
                            id="OPD-Consultation"
                            value="offline"
                            onClick={(e) => {
                              inputHandler(e);
                            }}
                            checked={formData.type === "offline" ? "true" : ""}
                          />
                          <label htmlFor="OPD-Consultation">
                            OPD Consultation
                          </label>
                          <br />
                          <input
                            name="type"
                            type="radio"
                            id="online-consultation"
                            value="online"
                            onClick={(e) => {
                              inputHandler(e);
                            }}
                            checked={formData.type === "online" ? "true" : ""}
                          />
                          <label htmlFor="online-consultation">
                            Online Consultation
                          </label>
                        </form>
                      </div>
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <Row>
                    <Col componentClass={ControlLabel} sm={12} md={4}></Col>
                    <Col sm={12} md={6}>
                      <Button
                        type="submit"
                        className="btn-fill btn btn-primary"
                        onClick={(e) => {
                          submitHandler(e);
                        }}
                        disabled={isLoading}
                      >
                        Save
                        {isLoading && <i class="fa fa-spinner fa-spin"></i>}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-fill btn-secondary pull-right"
            onClick={() => setModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};
export default withRouter(AddAppointment);
